<template>
  <div class="requests" :class="{ 'requests--archive': isArchive }">
    <entity-status-list :statuses="entityRequestsStatuses" class="flex-md-wrap mb-4">
      <requests-menu
        v-if="canUpdateRequests && !isArchive"
        class="ms-auto"
        @add-type="addType"
        @add-category="addCategory"
        @add-request="addRequest"
      />
    </entity-status-list>

    <div v-if="isLoading" class="d-flex align-center justify-center" style="min-height:200px">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <requests-missing-data v-else-if="isDataMissing" class="requests__missing-data" :is-archive="isArchive" />

    <template v-else>
      <template v-if="media.isMobile">
        <mobile-sort
          class="mt-2 mb-0 mb-md-6"
          :sort-list="sortedRequestsHeaders"
          :sort-by="activeHeader"
          :options.sync="options"
          :reverse="media.isMobile"
        />

        <div class="d-flex align-center mb-2">
          <mobile-search-menu
            v-model="query"
            :placeholder="$t('label.requests_search')"
            single-line
            full-width
            hide-details
          />

          <requests-modal-filters v-model="filtersData" />
        </div>

        <requests-mobile-list
          :requests="requests"
          :can-update-requests="canUpdateRequests"
          @open="openRequest"
          @edit="editRequest"
          @archive="archiveRequest"
        />
      </template>

      <template v-else>
        <search-bar
          v-model="query"
          class="mb-6"
          :placeholder="$t('label.requests_search')"
          single-line
          full-width
          hide-details
        />

        <requests-filter ref="filters" v-model="filtersData" :is-archive="isArchive" />

        <template v-if="!isLoading">
          <base-table
            :headers="requestsHeaders"
            :items="requests"
            :loading="tableLoading"
            :options.sync="options"
            :server-items-length="requestsLimit"
            hide-default-footer
            class="mt-4"
            @click:row="openRequest"
          >
            <template v-if="canUpdateRequests" #item.actions="{ item }" :class="isArchive && 'd-none'">
              <icon-button exact :width="36" @click.stop="editRequest(item)">
                <v-icon color="primary">mdi-pencil</v-icon>
              </icon-button>
              <icon-button exact :width="36" :disable="tableLoading" class="ms-3" @click.stop="archiveRequest(item)">
                <v-icon color="primary">mdi-archive-arrow-down</v-icon>
              </icon-button>
            </template>
          </base-table>
        </template>
      </template>

      <base-pagination v-if="pageCount > 1" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
// Components
import EntityStatusList from '@/components/EntityStatusList.vue';
import SearchBar from '@/components/SearchBar.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import BaseTable from '@/components/BaseTable.vue';
import IconButton from '@/components/IconButton.vue';
import BasePagination from '@/components/BasePagination.vue';
import MobileSort from '@/components/MobileSort/index.vue';
import RequestsMobileList from '@/components/Requests/MobileList.vue';

import RequestsMissingData from '@/components/Requests/MissingData.vue';
import RequestsMenu from '@/components/Requests/Menu.vue';
import RequestsFilter from '@/components/Requests/Filters.vue';

// Models
import { createModelData } from '@/schemas/createModelData';
import { schema } from '@/schemas/requestsFilter.schema';

// Services
import requestsService from '@/services/requests';

// Utils
import { debounce } from '@/utils/delay';
import { clone } from '@/utils/clone';
import { isEqual } from '@/utils/common';
import { camelToSnake } from '@/utils/formatters';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import joinWithLimit from '@/utils/joinWithLimit';

// Constants
import {
  REQUESTS_CREATE,
  REQUESTS_CATEGORY_CREATE,
  REQUESTS_TYPE_CREATE,
  REQUESTS,
  REQUESTS_ARCHIVE,
  REQUESTS_DETAILED,
  REQUESTS_EDIT,
} from '@/constants/routes';
import { ARCHIVED as ARCHIVED_STATUS } from '@/constants/entityStatuses';
import * as actions from '@/constants/actions';
import * as subjects from '@/constants/subjects';

export default {
  name: 'Requests',
  components: {
    EntityStatusList,
    RequestsMenu,
    RequestsMissingData,
    MobileSort,
    SearchBar,
    MobileSearchMenu,
    RequestsMobileList,
    RequestsFilter,
    RequestsModalFilters: () => import('@/components/Requests/ModalFilters.vue'),
    BaseTable,
    IconButton,
    BasePagination,
  },
  inject: ['media'],

  props: {
    status: { type: String, required: true },
  },
  data() {
    return {
      page: +this.$route.query.page || 1,
      requestsLimit: 10,
      isLoading: false,
      tableLoading: false,
      isDataMissing: false,
      pageCount: 1,
      query: '',
      filtersData: createModelData(schema),
      options: { sortBy: '', sortDesc: null },
      filters: {
        categoryTypes: undefined,
        categories: undefined,
      },
      headers: [
        {
          text: this.$t('label.title'),
          value: 'title',
          sortable: true,
          cellClass: 'text-truncate',
        },
        {
          text: this.$t('label.type'),
          value: 'categoryType',
          sortable: true,
          cellClass: 'text-truncate',
        },
        {
          text: this.$t('label.category'),
          value: 'category',
          sortable: true,
          cellClass: 'text-truncate',
        },
        {
          text: this.$t('label.project'),
          value: 'projects',
          sortable: false,
          cellClass: 'text-truncate',
        },
        {
          text: this.$t('label.building'),
          value: 'buildings',
          sortable: false,
          cellClass: 'text-truncate',
        },
        {
          text: this.$t('label.client_type'),
          value: 'clientType',
          sortable: true,
          cellClass: 'text-truncate',
        },
        {
          text: this.$t('label.payment'),
          value: 'paymentRequired',
          sortable: true,
          cellClass: 'text-truncate',
        },
      ],
      requests: [],
    };
  },
  computed: {
    requestsHeaders() {
      if (this.isArchive) {
        return this.headers;
      }
      return [
        ...this.headers,
        {
          text: null,
          value: 'actions',
          width: '100px',
          cellClass: 'px-0',
          sortable: false,
        },
      ];
    },
    entityRequestsStatuses() {
      return [
        { id: 0, text: this.$t('requests.requests'), name: REQUESTS },
        { id: 1, text: this.$t('requests.archive'), name: REQUESTS_ARCHIVE },
      ];
    },
    canUpdateRequests() {
      return this.$can(actions.UPDATE, subjects.REQUESTS);
    },
    isArchive() {
      return this.status === ARCHIVED_STATUS;
    },
    normalizedFilters() {
      return {
        categoryTypes: checkEmptyParams(this.filtersData?.categoryTypes)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filtersData?.categoryTypes),
        categories: checkEmptyParams(this.filtersData?.categories)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filtersData?.categories),
      };
    },
    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.requestsHeaders.find(requestsHeader => requestsHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },
    sortedRequestsHeaders() {
      return this.requestsHeaders.slice().sort((a, b) => {
        const order1 = a.order || Number.MAX_VALUE;
        const order2 = b.order || Number.MAX_VALUE;

        return order1 - order2;
      });
    },
    activeHeader() {
      return this.requestsHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },
    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { page: value },
        });
      },
    },
  },

  watch: {
    status() {
      if (this.isDataMissing) {
        this.isLoading = true;
        this.isDataMissing = false;
      }
      this.requests = [];
      this.query = '';
      this.filtersData = createModelData(schema);
      this.getRequests().then(() => {
        if (!this.requests.length && !this.query) {
          this.isDataMissing = true;
        }
      });
    },
    query() {
      this.debouceFetch();
    },
    normalizedFilters: {
      handler(newValue, oldValue) {
        if (isEqual(newValue, oldValue)) {
          return;
        }
        this.debouceFetch();
        this.isDataMissing = false;
      },
      deep: true,
    },
    filtersData: {
      handler(newValue) {
        this.updateFilters(newValue);
      },
      deep: true,
    },
    page() {
      this.getRequests();
    },
    async orderBy() {
      this.debouceFetch();
    },
    // eslint-disable-next-line
    '$route.query.page': function(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.page = +newValue || 1;
    },
  },

  mounted() {
    this.isLoading = true;
    this.getRequests().then(() => {
      if (!this.requests.length && !this.query) {
        this.isDataMissing = true;
      }
    });
  },

  methods: {
    // eslint-disable-next-line func-names
    debouceFetch: debounce(function() {
      this.getRequests();
    }, 300),

    async getRequests() {
      this.tableLoading = true;
      const params = {
        search: this.query,
        limit: this.requestsLimit,
        offset: this.requestsLimit * (this.page - 1),
        orderBy: this.orderBy,
        isArchived: this.isArchive,
      };

      const payload = {
        categoryTypes: this.normalizedFilters.categoryTypes,
        categories: this.normalizedFilters.categories,
      };

      try {
        const data = await requestsService.getRequests(params, payload);

        const { count, results } = data;

        this.requests = results;
        this.requests = this.formatRequests(results);

        this.pageCount = Math.ceil(count / this.requestsLimit);
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
      }
    },

    addRequest() {
      this.$router.push({
        name: REQUESTS_CREATE,
        params: { prevPage: this.$route },
      });
    },

    addType() {
      this.$router.push({
        name: REQUESTS_TYPE_CREATE,
        params: { prevPage: this.$route },
      });
    },

    addCategory() {
      this.$router.push({
        name: REQUESTS_CATEGORY_CREATE,
        params: { prevPage: this.$route },
      });
    },

    openRequest(item) {
      this.$router.push({
        name: REQUESTS_DETAILED,
        params: {
          id: item.id,
          prevPage: this.$route,
        },
      });
    },

    editRequest(item) {
      this.$router.push({
        name: REQUESTS_EDIT,
        params: {
          id: item.id,
          prevPage: this.$route,
        },
      });
    },

    archiveRequest(item) {
      requestsService.archiveRequest(item.id, !this.isArchive).then(() => {
        this.isLoading = true;

        this.getRequests().finally(() => {
          this.isLoading = false;
          // this.loadingContracts = this.loadingContracts.filter(id => id !== usageId);
        });
      });
    },

    formatRequests(requests) {
      return requests.map(request => ({
        ...request,
        category: request.category.title,
        projects: this.joinWithLimit(request.projects),
        buildings: this.joinWithLimit(request.buildings),
        clientType: this.$t(`client.${request.clientType}`),
        paymentRequired: request.paymentRequired ? this.$t('yes') : this.$t('no'),
      }));
    },

    updateFilters(filters) {
      if (filters.categoryTypes) {
        this.filters.categoryTypes = clone(filters.categoryTypes);
      }

      if (filters.categories) {
        this.filters.categories = clone(filters.categories);
      }
    },
    joinWithLimit,
  },
};
</script>
<style lang="scss">
.requests {
  height: 100%;

  .v-data-table {
    td,
    th {
      max-width: 1px;
    }
  }

  &--archive {
    .v-data-table td {
      color: rgba($--black-color-0, 0.5);
    }
  }

  &__missing-data {
    height: 100%;
  }
}
</style>
