<template>
  <base-mobile-list class="requests-mobile-list mt-6" :items="requests">
    <template v-slot:item="{ item }">
      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            <view-list-field :label="$t('label.title')">
              <v-btn
                text
                x-small
                color="primary"
                class="text-body-2 font-weight-medium px-0 text-none py-0 px-0"
                @click.stop="$emit('open', item)"
              >
                {{ item.title }}
              </v-btn>
            </view-list-field>
          </v-col>
          <v-col cols="6" class="pl-1">
            <view-list-field :label="$t('label.type')">
              {{ item.type }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col>
            <view-list-field :label="$t('label.category')">
              {{ item.category }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            <view-list-field :label="$t('label.project')">
              {{ item.project }}
            </view-list-field>
          </v-col>
          <v-col cols="6" class="pl-1">
            <view-list-field :label="$t('label.building')">
              {{ item.building }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            <view-list-field :label="$t('label.client_type')">
              {{ item.clientType }}
            </view-list-field>
          </v-col>
          <v-col cols="6" class="pl-1">
            <view-list-field :label="$t('label.payment')">
              {{ item.payment }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div v-if="canUpdateRequests" class="mt-6">
        <v-row no-gutters>
          <v-col>
            <icon-button exact :width="36" @click.stop="$emit('edit', item)">
              <v-icon color="primary">mdi-pencil</v-icon>
            </icon-button>
            <icon-button exact :width="36" class="ms-3" @click.stop="$emit('archive', item)">
              <v-icon color="primary">mdi-archive-arrow-down</v-icon>
            </icon-button>
          </v-col>
        </v-row>
      </div>
    </template>
  </base-mobile-list>
</template>

<script>
// Components
import BaseMobileList from '@/components/BaseMobileList.vue';
import ViewListField from '@/components/ViewListField.vue';
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'RequestsMobileList',
  components: { BaseMobileList, ViewListField, IconButton },
  props: {
    requests: { type: Array, required: true },
    canUpdateRequests: { type: Boolean, default: false },
  },
};
</script>
